import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authSlice } from '../features/auth';

import { alumniServices, userServices } from '../services';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  // ... other reducers
  [alumniServices.reducerPath]: alumniServices.reducer,
  [userServices.reducerPath]: userServices.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  // devTools: process.env.NODE_ENV === 'production',
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    // ... other middleware
    alumniServices.middleware,
    userServices.middleware,
  ],
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;

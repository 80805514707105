/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { useLocation, Location, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { ContentHeader } from '../components';
import {
  useGetUserDetailQuery,
  UserResponseType,
  useUpdateUserMutation,
  useAddUserMutation,
} from '../services';

interface LocationWithId extends Location {
  state: {
    id: string;
  }
}

interface FormStateType extends UserResponseType {
  password?: string;
}

export default function UserPage() {
  const [formState, setForm] = useState<FormStateType>({
    id: '',
    name: '',
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<string | null>(null);

  const loadingBarRef = useRef<LoadingBarRef>(null);
  const location = useLocation() as LocationWithId;
  const navigate = useNavigate();

  const { data, refetch } = useGetUserDetailQuery({
    id: userId,
  }, {
    skip: !userId,
  });
  const [updateUser] = useUpdateUserMutation();
  const [addUser] = useAddUserMutation();

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...formState,
      [e.target.name]: e.target.value,
    });
  }, [setForm, formState]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    loadingBarRef.current?.continuousStart(0, 30);

    if (userId) {
      updateUser(formState)
        .unwrap()
        .then(() => {
          loadingBarRef.current?.complete();
          toast.success('User updated successfully');
        })
        .catch((err: any) => {
          loadingBarRef.current?.complete();
          toast.error(err.message);
        })
        .finally(() => {
          setLoading(false);
          navigate('/users');
        });
    } else {
      addUser(formState)
        .unwrap()
        .then(() => {
          loadingBarRef.current?.complete();
          toast.success('User added successfully');
        })
        .catch((err: any) => {
          loadingBarRef.current?.complete();
          toast.error(err.message);
        })
        .finally(() => {
          setLoading(false);
          navigate('/users');
        });
    }
  };

  useEffect(() => {
    if (data?.data) {
      loadingBarRef.current?.complete();

      setForm((prevState: FormStateType) => ({
        ...prevState,
        ...data.data,
      }));

      setTimeout(() => setLoading(false), 1000);
    }
  }, [data, loadingBarRef]);

  useEffect(() => {
    loadingBarRef.current?.continuousStart(0, 60);

    if (location.state) {
      setUserId(location?.state?.id);
      refetch();
      loadingBarRef.current?.complete();
    } else {
      setTimeout(() => {
        setLoading(false);
        loadingBarRef.current?.complete();
      }, 1000);
    }
  }, [location.state?.id]);

  return (
    <>
      <LoadingBar height={5} color="#3b82f6" ref={loadingBarRef} />
      <div id="tw-app-container" className="tw-app-container">
        <ContentHeader
          title={userId ? 'Edit User' : 'Tambah User'}
          subtitle={userId ? 'Edit user' : 'Tambah user'}
          showInput={false}
          showButton={false}
          showBackButton
          onClickBackButton={() => navigate('/users')}
        />

        <form onSubmit={onSubmit}>
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-name" className="tw-flex-1 tw-w-full tw-mb-2">Nama</label>
            <input
              type="text"
              id="form-name"
              name="name"
              value={formState.name}
              onChange={handleOnChange}
              pattern="[a-zA-Z0-9 ]+"
              className="tw-form-control tw-w-full md:tw-flex-1"
              required
              placeholder="Name"
              disabled={loading}
            />
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-email" className="tw-flex-1 tw-w-full tw-mb-2">Email</label>
            <input
              type="text"
              id="form-email"
              name="email"
              value={formState.email}
              onChange={handleOnChange}
              className="tw-form-control tw-w-full md:tw-flex-1"
              required
              placeholder="Email Address"
              disabled={loading}
            />
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-password" className="tw-flex-1 tw-w-full tw-mb-2">Password</label>
            <input
              type="password"
              id="form-password"
              name="password"
              value={formState.password}
              onChange={handleOnChange}
              pattern="[a-zA-Z0-9!@#$%&]+"
              className="tw-form-control tw-w-full md:tw-flex-1"
              placeholder={
                userId ? 'Kosongkan jika tidak ingin mengubah password' : 'Password'
              }
              required={!userId}
              disabled={loading}
            />
          </div>

          <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row-reverse tw-justify-center tw-items-center tw-mt-8 tw-mb-4">
            <button
              type="submit"
              className="tw-button-primary tw-w-full md:tw-max-w-[200px]"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Submit'}
            </button>

            <button
              type="button"
              onClick={() => navigate('/users')}
              className="tw-button-default tw-w-full md:tw-max-w-[200px]"
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FiUserCheck, FiUsers, FiLogOut } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { mainContext } from '../app/context';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { logout } from '../features/auth';

import type { SidebarMenuType } from '../app/types';

interface PropTypes {
  className?: string;
}

export default function Sidebar({
  className,
}: PropTypes) {
  const { main, setMain } = React.useContext(mainContext);
  const { user } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();

  const handleToggleSidebar = () => {
    setMain({ ...main, isSidebarOpen: !main.isSidebarOpen });
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = '/';
  };

  const menuList: SidebarMenuType[] = [
    {
      title: 'Alumni',
      icon: FiUserCheck,
      path: '/',
    },
    {
      title: 'Users',
      icon: FiUsers,
      path: '/users',
    },
  ];

  const handleMenuClick = () => {
    setMain({ ...main, isSidebarOpen: false });
  };

  return (
    <div
      className={`tw-sidebar ${main.isSidebarOpen && 'open'} ${className}`}
      onClick={handleToggleSidebar}
    >
      <div
        className={`tw-sidebar-container ${main.isSidebarOpen && 'open'}`}
        onClick={(event) => event.stopPropagation()}
      >
        <ul id="sidebar-menu-list">
          {menuList.flatMap((menu: SidebarMenuType) => (
            <li key={menu.title}>
              <NavLink to={menu.path} onClick={handleMenuClick}>
                <menu.icon className="tw-w-4 tw-h-4" />
                <span className="tw-ml-2">{menu.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>

        <div id="sidebar-user-info" className="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-4 tw-px-6 tw-gap-4 tw-border-t">
          <img
            src={`https://cdn.statically.io/avatar/${user?.name}`}
            alt={user?.name}
            className="tw-w-8 tw-h-8 tw-rounded-full"
          />

          <div className="tw-w-auto">
            <span className="tw-block tw-font-semibold tw-leading-3 tw-text-slate-700 tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-w-full tw-max-w-[125px]">{user?.name}</span>
            <small className="tw-text-[0.75rem] tw-tracking-normal tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-block tw-w-full tw-max-w-[125px]">{user?.email}</small>
          </div>

          <button
            type="button"
            className="tw-ml-2"
            title="Logout"
            aria-label="Logout"
            onClick={handleLogout}
          >
            <FiLogOut className="tw-w-4 tw-h-4 hover:tw-text-red-500" />
          </button>
        </div>
      </div>
    </div>
  );
}

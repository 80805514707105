const LocalStorage = {
  get: <T>(key: string): T | null => {
    const item = localStorage.getItem(key);

    if (item) {
      return JSON.parse(item);
    }

    return null;
  },
  set: <T>(key: string, value: T): void => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key: string): void => {
    localStorage.removeItem(key);
  },
  clear: (): void => {
    localStorage.clear();
  },
};

const SessionStorage = {
  get: <T>(key: string): T | null => {
    const item = sessionStorage.getItem(key);

    if (item) {
      return JSON.parse(item);
    }

    return null;
  },
  set: <T>(key: string, value: T): void => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key: string): void => {
    sessionStorage.removeItem(key);
  },
  clear: (): void => {
    sessionStorage.clear();
  },
};

export {
  LocalStorage,
  SessionStorage,
};

import redaxios from 'redaxios';
import type { Options } from 'redaxios';

const apiUrl = process.env.REACT_APP_API_URL as string;
const apiKey = process.env.REACT_APP_API_KEY as string;

if (!apiUrl) throw new Error('REACT_APP_API_URL is not defined');
if (!apiKey) throw new Error('REACT_APP_API_KEY is not defined');

const instance = redaxios.create({
  baseURL: apiUrl,
  headers: {
    'x-api-key': apiKey,
  },
});

const fetch = (url: string, options: Options) => instance({
  url,
  ...options,
});

export default fetch;

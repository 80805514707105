/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import type { LoadingBarRef } from 'react-top-loading-bar';
import { toast } from 'react-toastify';
import { ContentHeader } from '../components';

import {
  useUpdateAlumniMutation, useAddAlumniMutation, useGetAlumniDetailQuery,
} from '../services';

interface LocationCustomType extends Location {
  state: {
    id: string | null;
  }
}

interface AlumniFormStateType {
  foto: any;
  preview: string;
  nama: string;
  jurusan: string;
  tahun_lulus: string | number;
  bekerja_sebagai: string;
  testimoni: string;
  is_published: 1 | 0;
}

export default function AlumniPage() {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [alumniId, setAlumniId] = React.useState<string | null>(null);
  const [formState, setForm] = React.useState<AlumniFormStateType>({
    foto: undefined,
    preview: '',
    nama: '',
    jurusan: '',
    tahun_lulus: 0,
    bekerja_sebagai: '',
    testimoni: '',
    is_published: 1,
  });

  const imageRef = useRef<HTMLImageElement>(null);
  const inputImageRef = useRef<HTMLInputElement>(null);
  const location = useLocation() as LocationCustomType;
  const navigate = useNavigate();
  const loadingBarRef = useRef<LoadingBarRef>(null);

  const { data, refetch } = useGetAlumniDetailQuery({
    id: alumniId ?? '',
  }, {
    skip: !alumniId,
  });
  const [updateAlumni] = useUpdateAlumniMutation();
  const [addAlumni] = useAddAlumniMutation();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...formState,
      [name]: value,
    });
  };

  const handleOnChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...formState,
      [name]: value,
    });
  };

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];

    if (!file) {
      toast.error('Please select an image');
      return;
    }

    const previewImage = URL.createObjectURL(file as File);

    setForm({
      ...formState,
      foto: file,
      preview: previewImage,
    });
  };

  const handleOnClickPublish = () => {
    setForm({
      ...formState,
      is_published: formState.is_published === 1 ? 0 : 1,
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (alumniId) {
      const formData = new FormData();

      if (formState.foto) {
        formData.append('foto', formState.foto);
      }
      formData.append('id', alumniId);
      formData.append('nama', formState.nama);
      formData.append('jurusan', formState.jurusan);
      formData.append('tahun_lulus', formState.tahun_lulus.toString());
      formData.append('bekerja_sebagai', formState.bekerja_sebagai);
      formData.append('testimoni', formState.testimoni);
      formData.append('is_published', formState.is_published.toString());

      updateAlumni(formData)
        .unwrap()
        .then(() => {
          toast.success('Successfully updated');
          setLoading(false);
        })
        .catch(() => {
          toast.error('Failed to update');
        })
        .finally(() => {
          navigate('/');
        });
    } else {
      const formData = new FormData();

      formData.append('foto', formState.foto);
      formData.append('nama', formState.nama);
      formData.append('jurusan', formState.jurusan);
      formData.append('tahun_lulus', formState.tahun_lulus.toString());
      formData.append('bekerja_sebagai', formState.bekerja_sebagai);
      formData.append('testimoni', formState.testimoni);
      formData.append('is_published', formState.is_published.toString());

      addAlumni(formData)
        .unwrap()
        .then(() => {
          toast.success('Successfully added');
          setLoading(false);
        })
        .catch(() => {
          toast.error('Failed to add');
        })
        .finally(() => {
          navigate('/');
        });
    }
  };

  const YEAR_LIST = () => {
    const yearList = [];
    // eslint-disable-next-line no-plusplus
    for (let i = new Date().getFullYear() - 5; i <= new Date().getFullYear(); i++) {
      yearList.push(i);
    }

    return yearList;
  };

  useEffect(() => {
    if (data?.data) {
      loadingBarRef.current?.continuousStart(0, 60);

      setForm({
        foto: undefined,
        preview: data.data.foto,
        nama: data.data.nama,
        jurusan: data.data.jurusan,
        tahun_lulus: data.data.tahun_lulus,
        bekerja_sebagai: data.data.bekerja_sebagai,
        testimoni: data.data.testimoni,
        is_published: data.data.is_published,
      });

      setTimeout(() => {
        setLoading(false);
        loadingBarRef.current?.complete();
      }, 1000);
    }
  }, [data?.data]);

  useEffect(() => {
    loadingBarRef.current?.continuousStart(0, 60);

    if (location.state?.id) {
      setAlumniId(location.state.id);
      refetch();
    } else {
      setTimeout(() => {
        loadingBarRef.current?.complete();
        setLoading(false);
      }, 500);
    }
  }, [location.state?.id]);

  return (
    <>
      <LoadingBar height={5} color="#3b82f6" ref={loadingBarRef} />
      <div id="tw-app-container" className="tw-app-container">
        <ContentHeader
          title={alumniId ? 'Edit Alumni' : 'Tambah Alumni'}
          subtitle={alumniId ? `Edit data alumni ${data?.data?.nama}` : 'Tambah data alumni'}
          showInput={false}
          showButton={false}
          showBackButton
          onClickBackButton={() => navigate('/')}
        />

        <form onSubmit={onSubmit} method="post" encType="multipart/form-data">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-start tw-mb-4">
            <label htmlFor="form-foto" className="md:tw-w-1/2 tw-w-full tw-mb-2 md:tw-mt-1">Foto</label>

            <div className="tw-relative tw-w-full md:tw-w-1/2 tw-flex tw-items-center tw-justify-center md:tw-justify-start">
              <input
                type="file"
                accept="image/*"
                id="form-foto"
                name="foto"
                onChange={handleUploadImage}
                ref={inputImageRef}
                className="tw-absolute tw-sr-only tw-opacity-0"
                placeholder="Foto"
                required={!alumniId}
                disabled={isLoading}
              />

              {alumniId && formState.foto === undefined && (
                <div className="tw-w-44 tw-flex tw-flex-col tw-rounded-lg">
                  <img src={`${process.env.REACT_APP_IMAGE_URL}/${formState.preview}`} alt="foto" className="tw-w-44 tw-h-44 tw-object-cover tw-rounded-lg" />

                  <label
                    htmlFor="form-foto"
                    className="tw-button-default tw-rounded-full tw-text-sm tw-mt-2 tw-py-2 tw-px-3 tw-text-center tw-cursor-pointer"
                    title="Ubah"
                  >
                    Ubah
                  </label>
                </div>
              )}

              {formState.foto && (
                <div className="tw-w-44 tw-flex tw-flex-col tw-rounded-lg">
                  <img ref={imageRef} src={formState.preview} alt="foto" className="tw-w-44 tw-h-44 tw-object-cover tw-rounded-lg" />

                  <label
                    htmlFor="form-foto"
                    className="tw-button-default tw-rounded-full tw-text-sm tw-mt-2 tw-py-2 tw-px-3 tw-text-center tw-cursor-pointer"
                    title="Ubah"
                  >
                    Ubah
                  </label>
                </div>
              )}

              {formState.foto === undefined && formState.preview === '' && (
                <label
                  htmlFor="form-foto"
                  className="tw-w-44 tw-h-44 tw-rounded-lg tw-border tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-cursor-pointer tw-p-2"
                >
                  <FiUploadCloud className="tw-text-center tw-text-2xl tw-text-primary tw-w-10 tw-h-10" />
                  <span className="tw-mt-2">
                    Upload Foto
                    <small className="text sm tw-inline-block">klik untuk mengupload</small>
                  </span>
                </label>
              )}
            </div>
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-nama" className="tw-flex-1 tw-w-full tw-mb-2">Nama</label>
            <input
              type="text"
              id="form-nama"
              name="nama"
              value={formState.nama}
              onChange={handleOnChange}
              pattern="[a-zA-Z0-9 ]+"
              className="tw-form-control tw-w-full md:tw-flex-1"
              required
              placeholder="Nama Alumni"
              disabled={isLoading}
            />
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-jurusan" className="tw-flex-1 tw-w-full tw-mb-2">Jurusan</label>
            <input
              type="text"
              id="form-jurusan"
              name="jurusan"
              maxLength={3}
              max={3}
              value={formState.jurusan}
              onChange={handleOnChange}
              className="tw-form-control tw-w-full md:tw-flex-1"
              required
              placeholder="Jurusan (AKA,TKJ,TKR,TAV)"
              list="list-jurusan"
              disabled={isLoading}
            />

            <datalist id="list-jurusan">
              <optgroup label="Jurusan">
                <option value="AKA" />
                <option value="TKJ" />
                <option value="TKR" />
                <option value="TAV" />
              </optgroup>
            </datalist>
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-tahun-lulus" className="tw-flex-1 tw-w-full tw-mb-2">Tahun Lulus</label>
            <input
              type="number"
              id="form-tahun-lulus"
              name="tahun_lulus"
              max={new Date().getFullYear()}
              maxLength={4}
              value={formState.tahun_lulus}
              onChange={handleOnChange}
              className="tw-form-control tw-w-full md:tw-flex-1"
              required
              placeholder="Tahun Lulus"
              list="list-tahun-lulus"
              disabled={isLoading}
            />

            <datalist id="list-tahun-lulus">
              <optgroup label="Tahun Lulus">
                {YEAR_LIST().flatMap((year) => (
                  <option value={year.toString()} key={year.toString()} />
                ))}
              </optgroup>
            </datalist>
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-bekerja-sebagai" className="tw-flex-1 tw-w-full tw-mb-2">Bekerja Sebagai</label>
            <input
              type="text"
              id="form-bekerja-sebagai"
              name="bekerja_sebagai"
              value={formState.bekerja_sebagai}
              onChange={handleOnChange}
              className="tw-form-control tw-w-full md:tw-flex-1"
              required
              placeholder="Bekerja Sebagai"
              disabled={isLoading}
            />
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="form-testimoni" className="tw-flex-1 tw-w-full tw-mb-2">Testimoni</label>
            <textarea
              id="form-testimoni"
              name="testimoni"
              value={formState.testimoni}
              onChange={handleOnChangeTextarea}
              className="tw-form-control tw-w-full md:tw-flex-1"
              required
              placeholder="Testimoni"
              rows={15}
              disabled={isLoading}
            />
          </div>

          <div className="tw-flex tw-flex-row tw-items-center tw-mb-4">
            <label htmlFor="toggle-publish" className="tw-flex-1 tw-w-full tw-mb-2">Publish Alumni?</label>

            <div className="tw-flex-1 tw-flex tw-items-center tw-gap-2">
              <label className={`tw-switch ${formState.is_published === 1 && 'active'}`} title="Publish alumni">
                <input
                  type="checkbox"
                  id="toggle-publish"
                  name="is_published"
                  className="tw-absolute tw-opacity-0"
                  defaultChecked={formState.is_published === 1}
                  onClick={handleOnClickPublish}
                  disabled={isLoading}
                />
                <span
                  className="tw-switch-toggle"
                  tabIndex={0}
                />
              </label>
              {' '}
              <label htmlFor="toggle-publish">Publish</label>
            </div>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row-reverse tw-justify-center tw-items-center tw-mt-8 tw-mb-4">
            <button
              type="submit"
              className="tw-button-primary tw-w-full md:tw-max-w-[200px]"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </button>

            <button
              type="button"
              onClick={() => navigate('/')}
              className="tw-button-default tw-w-full md:tw-max-w-[200px]"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from 'react';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import type { LoadingBarRef } from 'react-top-loading-bar';
import { ContentHeader, ProfileCardWithOptions, ModalConfirmation } from '../components';
import { useDebounce } from '../hooks';

import { useGetAlumniQuery, useUpdateAlumniMutation, useDeleteAlumniMutation } from '../services';
import type { AlumniResponseType } from '../services';

export default function Dashboard() {
  const [keyword, setKeyword] = React.useState<string>('');
  const [selectedId, setSelectedId] = React.useState<string>('');
  const [isModalConfirmationVisible, setModalConfirmation] = React.useState<boolean>(false);

  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const navigate = useNavigate();
  const loadingBarRef = useRef<LoadingBarRef>(null);

  const { data: alumniData, isLoading: loadingAlumniData, refetch } = useGetAlumniQuery();
  const [updateAlumni] = useUpdateAlumniMutation();
  const [deleteAlumni] = useDeleteAlumniMutation();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.trim();
    setKeyword(searchValue);
  };

  const handleTogglePublish = async (data: AlumniResponseType) => {
    loadingBarRef.current?.continuousStart(0, 60);

    updateAlumni({
      ...data,
      is_published: data.is_published === 1 ? 0 : 1,
    })
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        refetch();
        loadingBarRef.current?.complete();
      });
  };

  const handleClickDelete = async (id: string) => {
    setSelectedId(id);
    setModalConfirmation(true);
  };

  const onDelete = async () => {
    deleteAlumni({
      id: selectedId,
    })
      .unwrap()
      .then(() => {
        toast.success('Alumni berhasil dihapus');
        refetch();
      })
      .catch(() => {
        toast.error('Alumni gagal dihapus');
      })
      .finally(() => {
        setModalConfirmation(false);
      });
  };

  const handleClickButton = () => {
    navigate('/alumni');
  };

  const handleOnClickEdit = (alumniId: string | null = null) => {
    navigate('/alumni', {
      state: {
        id: alumniId,
      },
    });
  };

  useEffect(() => {
    refetch();

    if (loadingAlumniData) {
      loadingBarRef.current?.continuousStart(0, 60);
    } else {
      loadingBarRef.current?.complete();
    }
  }, [loadingBarRef, loadingAlumniData]);

  const searchResult = React.useMemo(() => alumniData?.data?.filter(
    (alumni: AlumniResponseType) => alumni.nama.toLowerCase().includes(debouncedKeyword.toLowerCase()),
  ), [alumniData?.data, debouncedKeyword]);

  return (
    <>
      <LoadingBar height={5} color="#3b82f6" ref={loadingBarRef} />
      <div className="tw-app-container" id="app-container">
        <ContentHeader
          title="Dashboard Alumni"
          subtitle="Selamat datang di dashboard alumni, disini anda dapat melihat data alumni yang telah anda inputkan."
          onChange={handleOnChange}
          onButtonClick={handleClickButton}
        />

        {/* Card List */}
        <h2 className="tw-mt-4 lg:tw-mt-0 tw-text-2xl">Alumni</h2>
        <div className="tw-card-container tw-mt-4">
          {searchResult && searchResult?.length > 0 ? searchResult?.flatMap((alumni: AlumniResponseType) => (
            <ProfileCardWithOptions
              key={alumni.id}
              {...alumni}
              options={{
                onClickPublish: () => handleTogglePublish(alumni),
                onClickDelete: () => handleClickDelete(alumni.id),
                onClickEdit: () => handleOnClickEdit(alumni.id),
              }}
            />
          )) : alumniData?.data?.flatMap((alumni: AlumniResponseType) => (
            <ProfileCardWithOptions
              key={alumni.id}
              {...alumni}
              options={{
                onClickPublish: () => handleTogglePublish(alumni),
                onClickDelete: () => handleClickDelete(alumni.id),
                onClickEdit: () => handleOnClickEdit(alumni.id),
              }}
            />
          ))}

          {alumniData?.data?.length === 0 && (
            <div className="tw-text-center tw-text-red-500 tw-mt-4 tw-w-full tw-flex tw-items-center tw-justify-center tw-h-60">
              Alumni not Found
            </div>
          )}

          {loadingAlumniData && (
            <div className="tw-text-center tw-text-red-500 tw-mt-4 tw-w-full tw-flex tw-items-center tw-justify-center tw-h-44">
              <div className="tw-loading" />
            </div>
          )}
        </div>

        <ModalConfirmation
          state={isModalConfirmationVisible}
          text="apakah kamu yakin ingin menghapus Alumni ini?"
          onClickDelete={onDelete}
          onClickCancel={() => setModalConfirmation(false)}
        />
      </div>
    </>
  );
}

import type { RouterBaseType } from './types';
// Pages
import LoginPage from '../pages/login';
import Dashboard from '../pages/dashboard';
import AlumniPage from '../pages/alumni';
import UsersPage from '../pages/users';
import UserPage from '../pages/user';

const PrivateRoutes: RouterBaseType[] = [
  {
    path: '/',
    element: Dashboard,
  },
  {
    path: '/alumni',
    element: AlumniPage,
  },
  {
    path: '/users',
    element: UsersPage,
  },
  {
    path: '/user',
    element: UserPage,
  },
];

const PublicRoutes: RouterBaseType[] = [
  {
    path: '/',
    element: LoginPage,
  },
];

export {
  PrivateRoutes,
  PublicRoutes,
};

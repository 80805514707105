/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';

import type { LoginRequestType } from '../app/types';
import fetch from '../utils/fetch';
import { SessionStorage } from '../utils/storage';

export default function LoginPage() {
  const [formData, setFormData] = useState<LoginRequestType>({
    email: '',
    password: '',
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const pattern = formControls.find((item) => item.name === name)?.pattern;

    if (new RegExp(pattern ?? '').test(value)) {
      event.target.classList.remove('is-error');
    } else {
      event.target.classList.add('is-error');
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  }, [setFormData]);

  const formControls = [
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      value: formData.email,
      onChange: handleOnChange,
      pattern: /^[a-zA-Z0-9.@%+-]+@[a-zA-Z]+.[a-zA-Z]+.[a-zA-Z]+$/,
      title: 'Please enter a valid email address',
      placeholder: 'Email address',
      required: true,
      disabled: isLoading,
    },
    {
      label: 'Password',
      name: 'password',
      type: showPassword ? 'text' : 'password',
      value: formData.password,
      onChange: handleOnChange,
      pattern: /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@$#]{8,}$/,
      title:
        'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters',
      disabled: isLoading,
      placeholder: 'Password',
      required: true,
      isIconShow: true,
      icon: showPassword ? HiOutlineEyeOff : HiOutlineEye,
      onClickIcon: () => setShowPassword((prevState) => !prevState),
    },
  ];

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('/auth', {
        method: 'POST',
        data: formData as LoginRequestType,
      });

      if (response.status === 200) {
        const { token } = await response.data.data;
        const getUserProfile = await fetch(`/auth?token=${token}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (getUserProfile.status !== 200) {
          toast.error('Something went wrong');
        }

        const user = await getUserProfile.data.data;

        SessionStorage.set('token', token);
        SessionStorage.set('user', user);
        window.location.reload();
      }

      setTimeout(() => setLoading(false), 1000);
    } catch (error: any) {
      toast.error(error?.data.message);
      setTimeout(() => setLoading(false), 1000);
    }
  };

  return (
    <div id="login-container">
      <div className="tw-app-container tw-min-h-screen tw-w-full tw-flex tw-items-center tw-justify-center tw-p-6">
        <form className="tw-flex tw-flex-col tw-items-center tw-justify-center" onSubmit={onSubmit}>
          <img
            src="https://assets.yapenhasboediluhur.sch.id/img/logo-yapenhas-boedi-luhur.png"
            alt="logo"
            className="tw-w-[75px] tw-block tw-mb-1"
          />
          <h1 className="tw-text-2xl tw-font-medium tw-mb-5 tw-text-center">Dashboard Alumni</h1>

          {formControls.flatMap((formControl) => (
            <div
              key={formControl.label}
              id={`form-group-${formControl.label}`}
              className="tw-flex tw-flex-col tw-w-full tw-max-w-[275px] tw-mb-4 "
            >
              <label htmlFor={formControl.label}>{formControl.label}</label>

              <div className="tw-mt-1 tw-relative tw-flex tw-flex-row tw-items-center tw-w-full">
                <input
                  type={formControl.type}
                  name={formControl.name}
                  id={formControl.label}
                  className={`tw-form-control tw-w-full ${formControl.isIconShow && 'tw-pr-9'}`}
                  value={formControl.value}
                  onChange={formControl.onChange}
                  pattern={formControl.pattern.toString().replaceAll('/', '')}
                  title={formControl.title}
                  disabled={formControl.disabled}
                  required={formControl.required}
                  placeholder={formControl.placeholder}
                />

                {formControl.isIconShow && (
                  <formControl.icon
                    className="tw-absolute tw-text-lg tw-right-0 tw-mr-3 tw-cursor-pointer hover:tw-text-slate-800 focus:tw-text-slate-800"
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        formControl.onClickIcon();
                      }
                    }}
                    onClick={formControl.onClickIcon}
                  />
                )}
              </div>
            </div>
          ))}

          <div className="tw-flex tw-flex-col tw-w-full tw-max-w-[275px] tw-mb-4">
            <button
              type="submit"
              className="tw-btn tw-button-primary tw-w-full tw-flex tw-items-center tw-justify-center"
              disabled={isLoading}
            >
              {isLoading ? <div className="tw-loading tw-w-6 tw-h-6 tw-border-r-white tw-border-l-white" /> : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpResponse } from '../app/types';
import baseQuery from './base-service';

export interface AlumniResponseType {
  id: string;
  nama: string;
  foto: string;
  jurusan: 'AKA' | 'TKR' | 'TKJ' | 'TAV';
  tahun_lulus: string;
  bekerja_sebagai: string;
  testimoni: string;
  is_published: 1 | 0;
  created_at: string;
  updated_at: string;
}

export interface AlumniRequestType {
  nama: string;
  foto?: string | File | any;
  jurusan: 'AKA' | 'TKR' | 'TKJ' | 'TAV';
  tahun_lulus: string;
  bekerja_sebagai: string;
  testimoni: string;
  is_published: 1 | 0;
}

export type UpdateAlumniType = AlumniRequestType | FormData;

export const alumniServices = createApi({
  baseQuery,
  reducerPath: 'alumni',
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAlumni: builder.query<HttpResponse<AlumniResponseType[]>, void>({
      query: () => ({
        url: '/alumni/all',
        method: 'GET',
      }),
    }),
    getAlumniDetail: builder.query<HttpResponse<AlumniResponseType>, { id: string | null }>({
      query: ({ id }) => ({
        url: `/alumni/detail/${id}`,
        method: 'GET',
      }),
    }),
    addAlumni: builder.mutation<HttpResponse<AlumniResponseType>, FormData>({
      query: (data) => ({
        url: '/alumni',
        method: 'POST',
        body: data,
      }),
    }),
    updateAlumni: builder.mutation<HttpResponse<AlumniResponseType>, UpdateAlumniType>({
      query: (data) => ({
        url: '/alumni',
        method: 'PUT',
        body: data,
      }),
    }),
    deleteAlumni: builder.mutation<HttpResponse<AlumniResponseType>, { id: string }>({
      query: (data) => ({
        url: `/alumni/${data.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAlumniQuery,
  useGetAlumniDetailQuery,
  useAddAlumniMutation,
  useUpdateAlumniMutation,
  useDeleteAlumniMutation,
} = alumniServices;

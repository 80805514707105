/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import LoadingBar from 'react-top-loading-bar';
import type { LoadingBarRef } from 'react-top-loading-bar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ContentHeader, ModalConfirmation } from '../components';

import {
  useGetUserListQuery, useDeleteUserMutation, UserResponseType,
} from '../services';
import { useDebounce } from '../hooks';
import { useAppSelector } from '../app/hooks';

export default function UsersPage() {
  const [keyword, setKeyword] = useState<string>('');
  const [checkedId, setCheckedId] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const navigate = useNavigate();
  const { user: authUser } = useAppSelector(({ auth }) => auth);

  const { data, isLoading, refetch } = useGetUserListQuery();
  const [deleteUser, { isLoading: loadingUserDelete }] = useDeleteUserMutation();

  const loadingBarRef = useRef<LoadingBarRef>(null);

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  }, [setKeyword]);

  const filteredUsers = useMemo(() => {
    if (!data) return [];

    if (debouncedKeyword !== '') {
      return data?.data?.filter(
        (user: UserResponseType) => user.name.toLowerCase().includes(debouncedKeyword.toLowerCase())
          || user.email.toLowerCase().includes(debouncedKeyword.toLowerCase()),
      ).filter((user: UserResponseType) => {
        if (user.id === authUser?.id) return false;
        return true;
      });
    }

    return (data?.data)?.filter((user: UserResponseType) => {
      if (user.id === authUser?.id) return false;
      return true;
    });
  }, [data, debouncedKeyword]);

  const handleCheckAll = useCallback(() => {
    if (filteredUsers && checkedId.length === 0) {
      const checkedIds = filteredUsers?.map((user: UserResponseType) => user.id);

      setCheckedId(checkedIds as string[]);
    } else {
      setCheckedId([]);
    }
  }, [checkedId, filteredUsers]);

  const handleCheck = useCallback((id: string) => {
    if (checkedId.includes(id)) {
      setCheckedId(checkedId.filter((checked: string) => checked !== id));
    } else {
      setCheckedId([...checkedId, id]);
    }
  }, [checkedId]);

  const handleDeleteAll = useCallback(async () => {
    if (checkedId.length === 0) return;

    loadingBarRef.current?.continuousStart(0, 60);

    checkedId.forEach((id: string) => {
      deleteUser({ id }).unwrap()
        .then(() => {
          toast.success('User deleted successfully');
          setCheckedId((prevState: string[]) => prevState.filter((checked: string) => checked !== id));
        })
        .catch(() => {
          toast.error('Error deleting user');
        })
        .finally(() => {
          refetch();
        });
    });
  }, [checkedId, deleteUser, refetch]);

  const handleShowModal = useCallback((id: string | null = null) => {
    setSelectedId(id);
    setShowModal(true);
  }, [setSelectedId, setShowModal]);

  const handleDeleteOne = useCallback(async () => {
    if (!selectedId) return;

    loadingBarRef.current?.continuousStart(0, 60);

    deleteUser({ id: selectedId }).unwrap()
      .then(() => {
        toast.success('User deleted successfully');
        setShowModal(false);
      })
      .catch(() => {
        toast.error('Error deleting user');
      })
      .finally(() => {
        refetch();
      });
  }, [deleteUser, refetch, selectedId]);

  useEffect(() => {
    refetch();

    if (isLoading) {
      loadingBarRef.current?.continuousStart(0, 60);
    } else {
      loadingBarRef.current?.complete();
    }
  }, [data, isLoading, refetch]);

  return (
    <>
      <LoadingBar height={5} color="#3b82f6" ref={loadingBarRef} />
      <div className="tw-app-container" id="tw-app-container">
        <ContentHeader
          title="Users Page"
          subtitle="List of users"
          inputPlaceholder="Search by name or email"
          buttonText="Tambah User"
          onChange={handleOnChange}
          onButtonClick={() => navigate('/user')}
        />

        <div className="tw-mt-4">
          {checkedId && checkedId.length > 0 && (
            <button
              type="button"
              className="tw-button-delete tw-mt-2 tw-mb-4"
              onClick={handleDeleteAll}
              disabled={loadingUserDelete}
            >
              Hapus
            </button>
          )}

          <div className="tw-overflow-x-auto tw-rounded-md">
            <table className="tw-custom-table tw-w-full">
              <thead>
                <tr>
                  <th className="tw-w-4 tw-px-0">
                    <input
                      type="checkbox"
                      title="Pilih semua"
                      className="tw-align-middle tw-inline"
                      onChange={handleCheckAll}
                      checked={checkedId.length > 0 && checkedId.length === filteredUsers?.length}
                    />
                  </th>
                  <th>
                    <span>Opsi</span>
                  </th>
                  <th>
                    <span>Nama</span>
                  </th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers && filteredUsers?.length > 0 ? filteredUsers.map((user: UserResponseType) => {
                  const isChecked = checkedId?.map((id: string) => id === user.id).includes(true);

                  return (
                    <tr key={user.id}>
                      <td className="tw-w-4 tw-px-0">
                        <input
                          type="checkbox"
                          className="tw-align-middle tw-inline"
                          checked={isChecked}
                          onChange={() => handleCheck(user.id as string)}
                        />
                      </td>
                      <td className="tw-flex tw-items-center tw-gap-x-2">
                        <button
                          type="button"
                          className="tw-button-primary tw-p-2 tw-text-xs"
                          onClick={() => navigate('/user', {
                            state: {
                              id: user.id,
                            },
                          })}
                        >
                          Edit
                        </button>

                        <button
                          type="button"
                          className="tw-button-delete tw-p-2 tw-text-xs"
                          onClick={() => handleShowModal(user.id)}
                        >
                          Delete
                        </button>
                      </td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                    </tr>
                  );
                }) : (
                  <tr>
                    <td colSpan={4}>
                      <div className="tw-text-center">
                        <p>
                          Tidak ada data
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {isLoading && (
            <div className="tw-w-full tw-h-32 tw-flex tw-justify-center tw-items-center">
              <div className="tw-loading" />
            </div>
          )}

        </div>
      </div>

      <ModalConfirmation
        state={showModal}
        text="Apakah anda yakin ingin menghapus user ini?"
        onClickCancel={() => setShowModal(false)}
        onClickDelete={handleDeleteOne}
      />
    </>
  );
}

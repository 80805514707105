/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

interface Props {
  state: boolean;
  text: string;
  onClickDelete: () => void;
  onClickCancel: () => void;
}

export default function ModalConfirmation({
  state,
  text = '',
  onClickDelete,
  onClickCancel,
}: Props) {
  return (
    <>
      {state && (
        <div className="tw-fixed tw-top-0 tw-left-0 tw-z-[100] tw-bg-black tw-bg-opacity-25 tw-w-full tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-p-6">
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-white tw-rounded-md tw-p-6 tw-w-full tw-max-w-[400px]">
            <h2 className="tw-text-center">
              {text}
              ?
            </h2>

            <div className="tw-flex tw-items-center tw-gap-2 tw-mt-4">
              <button
                type="button"
                className="tw-button-default"
                onClick={onClickCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="tw-button-delete"
                onClick={onClickDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

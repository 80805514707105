/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';

import { PrivateRoutes, PublicRoutes } from '../app/router-config';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import fetch from '../utils/fetch';
import { logout, setCredentials } from '../features/auth';
import { SessionStorage } from '../utils/storage';

import Sidebar from './sidebar';
import Navbar from './navbar';

import type { RouterBaseType } from '../app/types';

interface Props {
  routes: RouterBaseType[];
}

function PageWithNavbar({ routes }: Props) {
  return (
    <>
      <Navbar />
      <Routes>
        {routes.flatMap((route: RouterBaseType) => (
          <Route key={route.path} path={route.path} element={<route.element />} />
        ))}
      </Routes>
    </>
  );
}

export default function Router() {
  const { isAuthenticated, token, user } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    async function check() {
      await fetch(`/auth?token=${token}`, {
        method: 'GET',
      })
        .then((res: any) => {
          if (res.status === 200) {
            dispatch(
              setCredentials({
                isAuthenticated: true,
                token,
                user: res.data.data,
              }),
            );

            SessionStorage.set('user', res.data.data);
          }
        })
        .catch((err: any) => {
          dispatch(logout());

          if (window.location.pathname !== '/') {
            window.location.href = '/';
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setTimeout(() => check(), 1000);
  }, [token, isAuthenticated]);

  if (isLoading) {
    return (
      <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center">
        <div className="tw-loading" />
      </div>
    );
  }

  if (!isAuthenticated || !token || !user) {
    return (
      <BrowserRouter>
        <Routes>
          {PublicRoutes.flatMap((route: RouterBaseType) => (
            <Route key={route.path} path={route.path} element={<route.element />} />
          ))}
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <div className="tw-flex tw-flex-row relative">
        <Sidebar />

        <div className="tw-w-full tw-overflow-y-auto tw-relative tw-max-h-screen">
          <PageWithNavbar routes={PrivateRoutes} />
        </div>
      </div>
    </BrowserRouter>
  );
}

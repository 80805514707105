/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
import React from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';

interface CardOptions {
  onClickDelete?: () => void;
  onClickEdit?: () => void;
  onClickPublish?: () => void;
  loadingOnPublish?: boolean;
}

interface Props {
  id: string;
  nama: string;
  jurusan: 'AKA' | 'TKR' | 'TKJ' | 'TAV';
  foto: string;
  tahun_lulus: string;
  bekerja_sebagai: string;
  is_published: 1 | 0;
  options?: CardOptions;
}

export default function ProfileCardWithOptions({
  id,
  nama,
  jurusan,
  foto,
  tahun_lulus,
  bekerja_sebagai,
  is_published,
  options,
}: Props) {
  const [isOptionsVisible, setIsOptionsVisible] = React.useState<boolean>(false);

  return (
    <div className="tw-card tw-border tw-rounded-md tw-p-4 tw-flex tw-flex-col tw-justify-center tw-items-center tw-relative">
      <button
        type="button"
        className="tw-absolute tw-top-3 tw-right-3 tw-z-10"
        onClick={() => setIsOptionsVisible(!isOptionsVisible)}
      >
        <FiMoreHorizontal className="tw-w-6 tw-h-6" />
      </button>

      {isOptionsVisible && (
        <div className="tw-absolute tw-top-10 tw-right-3 tw-z-10 tw-border tw-bg-white tw-shadow tw-rounded tw-min-w-[125px]">
          <ul>
            <li
              className="tw-py-2 tw-px-3 tw-cursor-pointer hover:tw-bg-slate-100"
              title="Edit Profile"
              onClick={options?.onClickEdit}
            >
              Edit
            </li>
            <li
              className="tw-py-2 tw-px-3 tw-cursor-pointer hover:tw-bg-red-100 hover:tw-text-red-500"
              onClick={options?.onClickDelete}
              title="Delete Profile"
            >
              Delete
            </li>
          </ul>
        </div>
      )}

      <img src={`${process.env.REACT_APP_IMAGE_URL}/${foto}`} alt={nama} className="tw-w-24 tw-h-24 tw-object-cover tw-rounded-full" />

      {/* Detail Data */}
      <p className="tw-text-lg tw-font-semibold tw-mb-2 tw-mt-2">{nama}</p>
      <p className="tw-mb-1 tw-text-center">
        Lulusan
        {' '}
        <span className="tw-badge tw-font-semibold">{jurusan}</span>
        {' '}
        tahun
        {' '}
        {tahun_lulus}
      </p>
      <p className="tw-text-center">
        Bekerja sebagai
        {' '}
        <span className="tw-font-semibold">{bekerja_sebagai}</span>
      </p>

      <div className="tw-border-t tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-mt-4 tw-pt-2">
        <p>is published?</p>

        <label className={`tw-switch ${is_published === 1 && 'active'}`} title="Publish alumni">
          <input
            type="checkbox"
            id={`toggle-${id}`}
            name={`toggle-${id}`}
            className="tw-absolute tw-opacity-0"
            defaultChecked={is_published === 1}
            onClick={options?.onClickPublish}
            disabled={options?.loadingOnPublish}
          />
          <span
            className="tw-switch-toggle"
            tabIndex={0}
          />
        </label>
      </div>
    </div>
  );
}

import React from 'react';
import { mainContext } from '../app/context';

export default function Navbar() {
  const { main, setMain } = React.useContext(mainContext);

  return (
    <nav className="tw-navbar tw-w-full tw-fixed md:tw-static tw-top-0 tw-z-40 tw-border-b tw-bg-white">
      <div className="tw-h-16 tw-w-full tw-flex tw-items-center tw-justify-start tw-relative tw-px-4">
        <button
          className="tw-navbar-toggler md:tw-hidden"
          type="button"
          title="Open menu"
          onClick={() => setMain({ ...main, isSidebarOpen: !main.isSidebarOpen })}
        >
          <div className="icon" />
          <div className="icon" />
          <div className="icon" />
        </button>

        <img
          src="https://assets.yapenhasboediluhur.sch.id/img/logo-yapenhas-boedi-luhur.png"
          alt="Logo Yapenhas Boedi Luhur"
          className="tw-w-12 tw-h-12 tw-absolute -tw-translate-x-1/2 -tw-translate-y-1/2 tw-left-1/2 tw-top-1/2"
        />
      </div>
    </nav>
  );
}

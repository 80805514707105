import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AuthStateTypes } from './auth-types';
import { LocalStorage, SessionStorage } from '../../utils/storage';

const initialState: AuthStateTypes = {
  isAuthenticated: false,
  token: SessionStorage.get('token') ?? null,
  user: SessionStorage.get('user') ?? null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state: AuthStateTypes, { payload }: PayloadAction<AuthStateTypes>) => ({
      ...state,
      ...payload,
    }),
    logout: (state: AuthStateTypes): void => {
      SessionStorage.clear();
      LocalStorage.clear();

      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
    },
  },
});

export const {
  setCredentials,
  logout,
} = authSlice.actions;

export default authSlice;

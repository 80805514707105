/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import type { MainContextType, MainContextBaseType } from './types';

export const mainContext = createContext<MainContextType>({
  main: {
    isSidebarOpen: false,
  } as MainContextBaseType,
  setMain: () => {},
});

import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base-service';
import type { HttpResponse } from '../app/types';

export interface UserResponseType {
  id?: string;
  name: string;
  email: string;
}

export interface UserRequestType extends UserResponseType {
  password?: string;
}

export const userServices = createApi({
  baseQuery,
  reducerPath: 'user',
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getUserList: builder.query<HttpResponse<UserResponseType[]>, void>({
      query: () => ({
        url: '/auth/list',
        method: 'GET',
      }),
    }),
    getUserDetail: builder.query<HttpResponse<UserResponseType>, { id: string | null }>({
      query: ({ id }) => ({
        url: `/auth/detail/${id}`,
        method: 'GET',
      }),
    }),
    addUser: builder.mutation<HttpResponse<null>, UserRequestType>({
      query: (data) => ({
        url: '/auth/register',
        method: 'POST',
        body: data,
      }),
    }),
    updateUser: builder.mutation<HttpResponse<null>, UserRequestType>({
      query: (data) => ({
        url: `/auth/${data.id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteUser: builder.mutation<HttpResponse<UserResponseType>, { id: string | null }>({
      query: ({ id }) => ({
        url: `/auth/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetUserListQuery,
  useGetUserDetailQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
} = userServices;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
import React from 'react';
import { BsPlus } from 'react-icons/bs';
import { FiChevronLeft } from 'react-icons/fi';

interface Props {
  title?: string;
  subtitle?: string;
  inputType?: string;
  inputPlaceholder?: string;
  onChange?: (e: any) => void;
  buttonText?: string;
  onButtonClick?: () => void;
  showInput?: boolean;
  showButton?: boolean;
  showBackButton?: boolean;
  onClickBackButton?: () => void;
}

export default function ContentHeader({
  title = 'Header Title',
  subtitle = 'Header Subtitle',
  inputType = 'text',
  inputPlaceholder = 'Cari Alumni',
  onChange = () => {},
  buttonText = 'Tambah Alumni',
  onButtonClick = () => {},
  showInput = true,
  showButton = true,
  showBackButton = false,
  onClickBackButton = () => {},
}: Props) {
  return (
    <header className="tw-header tw-mt-12 md:tw-mt-0">
      <div className="tw-py-2 lg:tw-flex lg:tw-flex-row lg:tw-items-start lg:tw-justify-between">
        {/* Header Text */}
        <div id="header-text" className="tw-mb-4 lg:tw-max-w-md">
          {showBackButton ? (
            <div className="tw-mb-4">
              <button
                type="button"
                title="Back"
                className="tw-mr-4 tw-flex tw-items-center tw-text-primary tw-mb-4"
                onClick={onClickBackButton}
              >
                <FiChevronLeft />
                Back
              </button>
              <h1>
                {title}
              </h1>
            </div>
          ) : <h1 className="tw-mb-4">{title}</h1>}

          <span>{subtitle}</span>
        </div>

        {/* Header Searchbar */}
        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-start sm:tw-items-center tw-gap-2">
          {showInput && (
            <input
              type={inputType}
              placeholder={inputPlaceholder}
              className="tw-form-control tw-w-full sm:tw-w-[250px]"
              onChange={onChange}
            />
          )}

          {showButton && (
            <button
              type="button"
              title={buttonText}
              className="tw-button-primary tw-flex tw-items-center tw-gap-2 tw-whitespace-nowrap tw-py-2.5"
              onClick={onButtonClick}
            >
              <BsPlus calcMode="tw-w-10 tw-h-10" />
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </header>
  );
}

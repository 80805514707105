/* eslint-disable no-console */
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
// import { SessionStorage } from '../utils/storage';
import { RootState } from '../app/store';
import { logout } from '../features/auth';

const baseUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY as string;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('x-api-key', apiKey);

    return headers;
  },
});

// export default baseQuery;

const baseQueryWithLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, options) => {
  const result = await baseQuery(args, api, options);

  if (result.error) {
    console.error(result.error);
  }

  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
    return result;
  }

  return result;
};

export default baseQueryWithLogout;
